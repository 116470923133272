import { useEffect, useState } from "react";
import { ReactComponent as JimorrowLogo } from "./svgs/jimorrow logo.svg";
import aboutImage from "./images/idyllic cover.png";

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
  // eslint-disable-next-line react-hooks/exhaustive-deps
}

export default function MixingMastering() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const { width } = useWindowSize();

  // animation code
  useEffect(() => {
    const scrollElements = document.querySelectorAll(".js-scroll");

    const elementInView = (el, dividend = width > 720 ? 1.1 : 0.9) => {
      const elementTop = el.getBoundingClientRect().top;

      return (
        elementTop <=
        (window.innerHeight || document.documentElement.clientHeight) / dividend
      );
    };

    const elementOutofView = (el) => {
      const elementTop = el.getBoundingClientRect().top;

      return (
        elementTop >
        (window.innerHeight || document.documentElement.clientHeight)
      );
    };

    const displayScrollElement = (element) => {
      element.classList.add("scrolled");
    };

    const hideScrollElement = (element) => {
      element.classList.remove("scrolled");
    };

    const handleScrollAnimation = () => {
      scrollElements.forEach((el) => {
        if (elementInView(el, 1.1)) {
          displayScrollElement(el);
        } else if (elementOutofView(el)) {
          hideScrollElement(el);
        }
      });
    };

    window.addEventListener("scroll", () => {
      handleScrollAnimation();
    });

    window.scrollTo({ top: 1 });
    window.scrollTo({ top: 0 });
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = () => {
    setScrollPosition(window.pageYOffset);
  };

  return (
    <div className="App js-scroll fade-in">
      <div className={`navbar ${scrollPosition >= 20 ? "shadow" : ""}`}>
        {width > 950 ? (
          <div className="navbar-content centered">
            <a href="/" className="navbar-logo-container">
              <JimorrowLogo
                className={`navbar-logo ${scrollPosition < 20 ? "large" : ""}`}
              />
            </a>
          </div>
        ) : (
          <>
            <a href="/" className="navbar-content centered">
              <div className="navbar-logo-container">
                <JimorrowLogo className={`navbar-logo-corner`} />
              </div>
            </a>
          </>
        )}
      </div>

      <div className="section mixing-mastering">
        <div className="about-content">
          <div className="about-text-1 js-scroll slide-left">
            <span className="section-title">Mixing and Mastering Services</span>
            <br></br>
            <br></br>
            {`Do you wish you could mix and master your music like a pro? I want to help you release a mix you are confident with. I will work with up to 30 stems and will deliver you a mix and master that will compete with Skrillex. I am super flexible and will make edits for free. Contact me at `}
            <span className="make-bold">
              {" "}
              jimorrow<span className="period">.</span>com@gmail.com{" "}
            </span>
            {`if you're interested and if you are happy with the result feel free to tag me in your upload/post description and spread the word! :)`}
            <br></br>
            <br></br>
            {"1 master for $15*"}
            <br></br>
            {"2 masters for $25*"}
            <br></br>
            {"1 mix and master for $25*"}
            <br></br>
            {"2 mix and masters for $40*"}
            <br></br>
            <br></br>
            {
              "*Pricing may increase in the future based on demand so take advantage of these offers while they last"
            }
            {width > 720 && (
              <>
                <br></br>
                {"_"}
              </>
            )}
          </div>

          <div className="about-image-container js-scroll fade-in-bottom">
            <img className="about-image faded" src={aboutImage} alt="" />
          </div>

          <div className="about-text-2 js-scroll slide-right">
            <span className="section-title">Contact</span>
            <br></br>
            <br></br>
            <b>email//</b>
            <br></br>
            <p>jimorrow.com@gmail.com</p>
            <br></br>
            <br></br>
            <b>instagram//</b>
            <br></br>
            <p>@jimorrowmusic</p>
            <br></br>
            <br></br>
            <b>soundcloud//</b>
            <br></br>
            <p>@jimorrow</p>
          </div>
        </div>
      </div>
    </div>
  );
}
