import { useEffect, useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import {
	AWVideo,
	JimorrowMusicVideo,
	SoundcloudPlayer,
	SpotifyPlayer,
	YoutubePlaylist,
} from './embeds';

import homeVideo from './images/Website Vid.mp4';
import aboutImage from './images/SoundCloud Build.v1.jpg';
import sync1 from './images/rocket league.jpg';
import sync2 from './images/f1 22.jpg';

import art1 from './images/VIOLET COVER ART.png';
import art2 from './images/september cover art.png';
import art3 from './images/not in my life cover art.png';
import art4 from './images/jimorrow ca.png';
import art5 from './images/i dont sleep cover art.png';
import art6 from './images/years apart ca.png';
import art7 from './images/you saved me cover art.png';
import art8 from './images/IDYLLIC_COVER_ART_FINAL.png';

import jimorrowYmirIcon from './images/jimorrow-ymir-icon.png';

import planet from './images/planet.png';

import footerImage from './images/Jimorrow BG Wide transparent.png';

import { ReactComponent as Amazon } from './svgs/amazon.svg';
import { ReactComponent as Apple } from './svgs/apple.svg';
import { ReactComponent as Instagram } from './svgs/instagram.svg';
import { ReactComponent as Soundcloud } from './svgs/soundcloud.svg';
import { ReactComponent as SonusVos } from './svgs/sonusvos.svg';
import { ReactComponent as Spotify } from './svgs/spotify.svg';
import { ReactComponent as Twitter } from './svgs/twitter.svg';
import { ReactComponent as Youtube } from './svgs/youtube.svg';

import { ReactComponent as JimorrowLogo } from './svgs/jimorrow logo.svg';
import { ReactComponent as Exit } from './svgs/exit.svg';
import { ReactComponent as ArrowLeft } from './svgs/arrow-left.svg';
import { ReactComponent as ArrowRight } from './svgs/arrow-right.svg';
import { ReactComponent as RightArrow } from './svgs/right-arrow.svg';

const gallerySrcList = [art8, art1, art2, art3, art4, art5, art6, art7];

const galleryInfoList = [
	{
		title: 'IDYLLIC',
		desc: (
			<>
				{/* {"You changed for the better"}
        <br></br>
        {"I changed for you"}
        <br></br>
        {"This is the start of a new time together"} */}
			</>
		),
	},
	{
		title: 'VIOLET',
		desc: (
			<>
				{/* {"Cause you were my violet"}
        <br></br>
        {"And I don't wan't to lose you"}
        <br></br>
        {"But you're already lost"} */}
			</>
		),
	},
	{
		title: 'SEPTEMBER',
		desc: (
			<>
				{/* {"I learned how to run by falling"}
        <br></br>
        {"I learned how to think by messing up"}
        <br></br>
        {"I learned how to live cause I kept trying"} */}
			</>
		),
	},
	{
		title: 'NOT IN MY LIFE',
		desc: (
			<>
				{/* {"If only you were in my life"}
        <br></br>
        {"Maybe things would be alright"}
        <br></br>
        {"I'll be waiting here for you as long as I'm alive"} */}
			</>
		),
	},
	{
		title: 'JIMORROW (SONG)',
		desc: (
			<>
				{/* {"Life is a journey"}
        <br></br>
        {"You make it exciting"}
        <br></br>
        {"You make it worth living"} */}
			</>
		),
	},
	{
		title: "I DON'T SLEEP",
		desc: (
			<>
				{/* {"I don't sleep cause when I do"}
        <br></br>
        {"All my dreams are dreams of you"}
        <br></br>
        {"And in yours you say that we are falling down"} */}
			</>
		),
	},
	{
		title: 'YEARS APART',
		desc: (
			<>
				{/* {"Let the time take you"}
        <br></br>
        {"Let the time take you"}
        <br></br>
        {"Let the time take you"} */}
			</>
		),
	},
	{
		title: 'YOU SAVED ME',
		desc: (
			<>
				{/* {"When it got dark out you were my light"}
        <br></br>
        {"When I got lost you were my guide"}
        <br></br>
        {"Thank you for saving me"} */}
			</>
		),
	},
];

function useWindowSize() {
	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined,
	});
	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);
	return windowSize;
	// eslint-disable-next-line react-hooks/exhaustive-deps
}

export default function App() {
	const [scrollPosition, setScrollPosition] = useState(0);
	const { width } = useWindowSize();
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const [contentLoaded, setContentLoaded] = useState(false);
	const [galleryModalOpen, setGalleryModalOpen] = useState(false);
	const [galleryNumberSelected, setGalleryNumberSelected] = useState(null);
	const [sCorSpofity, setSCorSpofity] = useState(true);
	const [edmCinematicTab, setEdmCinematicTab] = useState(true);

	// animation code
	useEffect(() => {
		if (contentLoaded) {
			const scrollElements = document.querySelectorAll('.js-scroll');

			const elementInView = (el, dividend = width > 720 ? 1.1 : 0.9) => {
				const elementTop = el.getBoundingClientRect().top;

				return (
					elementTop <=
					(window.innerHeight || document.documentElement.clientHeight) /
						dividend
				);
			};

			const elementOutofView = (el) => {
				const elementTop = el.getBoundingClientRect().top;

				return (
					elementTop >
					(window.innerHeight || document.documentElement.clientHeight)
				);
			};

			const displayScrollElement = (element) => {
				element.classList.add('scrolled');
			};

			const hideScrollElement = (element) => {
				element.classList.remove('scrolled');
			};

			const handleScrollAnimation = () => {
				scrollElements.forEach((el) => {
					if (elementInView(el, 1.1)) {
						displayScrollElement(el);
					} else if (elementOutofView(el)) {
						hideScrollElement(el);
					}
				});
			};

			window.addEventListener('scroll', () => {
				handleScrollAnimation();
			});

			window.scrollTo({ top: 10 });

			setTimeout(() => {
				window.scrollTo({ top: 0 });
			}, 100);

			window.addEventListener('scroll', handleScroll);

			return () => {
				window.removeEventListener('scroll', handleScroll);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentLoaded]);

	const handleScroll = () => {
		setScrollPosition(window.pageYOffset);
	};

	const handleNavbarClick = () => setMobileMenuOpen(!mobileMenuOpen);

	const closeMobileMenu = () => {
		setMobileMenuOpen(false);
	};

	return (
		<div className='App js-scroll fade-in'>
			{contentLoaded && (
				<div
					className={`navbar ${
						scrollPosition >= 20 || mobileMenuOpen ? 'shadow' : ''
					}`}
				>
					{width > 950 ? (
						<div className='navbar-content'>
							<ScrollLink
								className='navbar-button'
								activeClass='active'
								to='about'
								smooth={true}
								offset={-88}
								duration={1000}
							>
								About
							</ScrollLink>
							<ScrollLink
								className='navbar-button'
								activeClass='active'
								to='music'
								smooth={true}
								offset={-86}
								duration={1000}
							>
								Music
							</ScrollLink>
							<ScrollLink
								className='navbar-button'
								activeClass='active'
								to='highlights'
								smooth={true}
								offset={-86}
								duration={1000}
							>
								Highlights
							</ScrollLink>

							<ScrollLink
								className='navbar-logo-container'
								activeClass='active'
								to='home'
								smooth={true}
								offset={0}
								duration={1000}
							>
								<JimorrowLogo
									className={`navbar-logo ${
										scrollPosition < 20 ? 'large' : ''
									}`}
								/>
							</ScrollLink>

							<ScrollLink
								className='navbar-button'
								activeClass='active'
								to='musicvideo'
								smooth={true}
								offset={-114}
								duration={1000}
							>
								Music Video
							</ScrollLink>
							<ScrollLink
								className='navbar-button'
								activeClass='active'
								to='gallery'
								smooth={true}
								offset={-80}
								duration={1000}
							>
								Gallery
							</ScrollLink>
							<ScrollLink
								className='navbar-button'
								activeClass='active'
								to='contact'
								smooth={true}
								offset={-66}
								duration={1000}
							>
								Contact
							</ScrollLink>
						</div>
					) : (
						<>
							<div className='navbar-content'>
								<ScrollLink
									className='navbar-logo-container'
									activeClass='active'
									to='home'
									smooth={true}
									offset={0}
									duration={1000}
								>
									<JimorrowLogo className={`navbar-logo-corner`} />
								</ScrollLink>

								<button
									className={
										mobileMenuOpen
											? 'navbar-toggle active'
											: 'navbar-toggle not-active'
									}
									onClick={handleNavbarClick}
								>
									<span></span>
									<span></span>
									<span></span>
								</button>
							</div>

							<div
								className={mobileMenuOpen ? 'blackBGNav active' : 'blackBGNav'}
								onClick={closeMobileMenu}
							/>
							<div className={mobileMenuOpen ? 'nav-menu active' : 'nav-menu'}>
								<ScrollLink
									className='navbar-button'
									activeClass='active'
									to='about'
									smooth={true}
									offset={-90}
									duration={1000}
									onClick={closeMobileMenu}
								>
									About
								</ScrollLink>
								<ScrollLink
									className='navbar-button'
									activeClass='active'
									to='music'
									smooth={true}
									offset={-90}
									duration={1000}
									onClick={closeMobileMenu}
								>
									Music
								</ScrollLink>
								<ScrollLink
									className='navbar-button'
									activeClass='active'
									to='highlights'
									smooth={true}
									offset={-90}
									duration={1000}
									onClick={closeMobileMenu}
								>
									Highlights
								</ScrollLink>
								<ScrollLink
									className='navbar-button'
									activeClass='active'
									to='musicvideo'
									smooth={true}
									offset={-90}
									duration={1000}
									onClick={closeMobileMenu}
								>
									Music Video
								</ScrollLink>
								<ScrollLink
									className='navbar-button'
									activeClass='active'
									to='gallery'
									smooth={true}
									offset={-90}
									duration={1000}
									onClick={closeMobileMenu}
								>
									Gallery
								</ScrollLink>
								<ScrollLink
									className='navbar-button'
									activeClass='active'
									to='contact'
									smooth={true}
									offset={-90}
									duration={1000}
									onClick={closeMobileMenu}
								>
									Contact
								</ScrollLink>
							</div>
						</>
					)}
				</div>
			)}

			<div className='section home js-scroll fade-in' id='home'>
				{contentLoaded && (
					<>
						{width > 720 ? (
							<>
								{/* <div className="home-section-conent">
								<h1 style={{ display: "none" }}>{"LIFE IS AN AMAZING SONG"}</h1>
								<div className="home-title">
									<div id="ab1">{"LIFE "}</div>
									<div id="ab2">{"IS "}</div>
									<div id="ab3">{"AN "}</div>
									<div id="ab4">{"AMAZING "}</div>
									<div id="ab5">{"SONG"}</div>
								</div>
							</div> */}
							</>
						) : (
							<>
								{/* <div className="home-section-conent">
									<h1 style={{ display: "none" }}>
										{"LIFE IS AN AMAZING SONG"}
									</h1>
									<div className="home-title">
										<div id="ab1">{"LIFE"}</div>
										<div id="ab2">{"IS"}</div>
										<div id="ab3">{"AN"}</div>
										<div id="ab4">{"AMAZING"}</div>
										<div id="ab5">{"SONG"}</div>
									</div>
								</div> */}
							</>
						)}
					</>
				)}

				<video
					preload='true'
					autoPlay='autoplay'
					loop='loop'
					muted={true}
					className='home-video'
					playsInline={true}
					onLoadedData={() => {
						console.log('Page has loaded. Welcome!');
						setContentLoaded(true);
					}}
				>
					<source src={homeVideo} type='video/mp4' />
				</video>

				{contentLoaded && (
					<div
						className={`home-social-section ${
							width > 720 ? 'js-scroll slide-left' : 'js-scroll fade-in-bottom'
						}`}
					>
						<a
							className='social-section-link'
							href='https://www.sonusvos.com/users/jimorrow/songs'
							target='_blank'
							rel='noopener noreferrer'
						>
							<SonusVos className='social-section-icon sonusvos' />
						</a>
						<a
							className='social-section-link'
							href='https://soundcloud.com/jimorrow'
							target='_blank'
							rel='noopener noreferrer'
						>
							<Soundcloud className='social-section-icon' />
						</a>
						<a
							className='social-section-link'
							href='https://open.spotify.com/artist/06qfSjDnOB1ZMdkynif9lv?si=gBRCH712TMywMAGskpmrOQ'
							target='_blank'
							rel='noopener noreferrer'
						>
							<Spotify className='social-section-icon' />
						</a>
						<a
							className='social-section-link'
							href='https://music.apple.com/us/artist/jimorrow/1536518557'
							target='_blank'
							rel='noopener noreferrer'
						>
							<Apple className='social-section-icon' />
						</a>
						<a
							className='social-section-link'
							href='https://gate.sc/?url=https%3A%2F%2Fmusic.amazon.com%2Fartists%2FB08LG1SWNW%2Fjimorrow%3FmarketplaceId%3DATVPDKIKX0DER%26musicTerritory%3DUS&token=d27629-1-1654116898542'
							target='_blank'
							rel='noopener noreferrer'
						>
							<Amazon className='social-section-icon' />
						</a>
						<a
							className='social-section-link'
							href='https://gate.sc/?url=https%3A%2F%2Fwww.youtube.com%2Fchannel%2FUCKVeyMqxQ-ebhTQRCJ1Bdog%2Ffeatured%3Fview_as%3Dsubscriber&token=1d3231-1-1654116898544'
							target='_blank'
							rel='noopener noreferrer'
						>
							<Youtube className='social-section-icon' />
						</a>
						<a
							className='social-section-link'
							href='https://twitter.com/Jimorrowmusic'
							target='_blank'
							rel='noopener noreferrer'
						>
							<Twitter className='social-section-icon' />
						</a>
						<a
							className='social-section-link'
							href='https://www.instagram.com/jimorrowmusic/'
							target='_blank'
							rel='noopener noreferrer'
						>
							<Instagram className='social-section-icon' />
						</a>
					</div>
				)}
			</div>

			{contentLoaded && (
				<>
					<div className='section about' id='about'>
						<div className='about-content'>
							<div className='about-text-1 js-scroll slide-left'>
								<span className='section-title'>About Me</span>
								<br></br>
								<br></br>
								Founder and CEO of{' '}
								<b>
									<a
										style={{ color: '#ff5e5e' }}
										href='https://www.sonusvos.com'
										target='_blank'
										rel='noopener noreferrer'
									>
										Sonus Vos
									</a>
								</b>
								<br></br>
								<br></br>
								{
									'Hey, my name is James but online I go by my stage name, Jimorrow! I am a programmer by day and a producer by night. You might know me from my biggest song, Light of Mine, which I released on Monstercat but I have also been featured on Trap Nation and more. I have accumulated over 15+ million streams as of June 2, 2024, accross all music platforms. I genuinely love making all kinds of music including electronic, future bass, house, trap, orchestral, and more. I first started learning how to make music at the age of 14 and it has been a wild journey since. Never could have imagined being where I am today and for that I am so grateful.'
								}
								<br></br>
								<br></br>
								{
									'My musical inspirations to name a few are Olafur Arnalds, Florian Christl, Emmit Fenn, Illenium, and many more.'
								}
								{width > 720 && (
									<>
										<br></br>
										{'_'}
									</>
								)}
							</div>

							<div className='about-image-container js-scroll fade-in-bottom'>
								<img className='about-image' src={aboutImage} alt='' />
							</div>

							<div className='about-text-2 js-scroll slide-right'>
								<span className='section-title'>Info</span>
								<br></br>
								<br></br>
								<b>residence//</b>
								<br></br>
								USA
								<br></br>
								<br></br>
								<b>born//</b>
								<br></br>
								2002
								<br></br>
								<br></br>
								<b>genres//</b>
								<br></br>
								ELECTRONIC<br></br>
								CINEMATIC
								<br></br>
								<br></br>
								<b>worked with//</b>
								<br></br>
								<a
									className='text-link'
									href='https://www.instagram.com/ymirymirymirymir'
									target='_blank'
									rel='noopener noreferrer'
								>
									YMIR
								</a>
								<br></br>
								<a
									className='text-link'
									href='https://www.instagram.com/whippedcream'
									target='_blank'
									rel='noopener noreferrer'
								>
									WHIPPED CREAM
								</a>
								<br></br>
								<a
									className='text-link'
									href='https://www.instagram.com/oneohkay/'
									target='_blank'
									rel='noopener noreferrer'
								>
									ONEOHKAY
								</a>
								<br></br>
								<a
									className='text-link'
									href='https://www.instagram.com/bexlomusic/'
									target='_blank'
									rel='noopener noreferrer'
								>
									BEXLO
								</a>
								<br></br>
								<br></br>
								<b>labels released with//</b>
								<br></br>
								<a
									className='text-link'
									href='https://www.monstercat.com/artist/jimorrow'
									target='_blank'
									rel='noopener noreferrer'
								>
									MONSTERCAT
								</a>
								<br></br>
								<a
									className='text-link'
									href='https://ecotonerecords.com/medusa-deluxe/'
									target='_blank'
									rel='noopener noreferrer'
								>
									ECOTONE RECORDS
								</a>
							</div>
						</div>
					</div>

					<div className='section music' id='music'>
						{width > 720 && (
							<>
								<img
									className='music-planet mp1 js-scroll slide-left'
									src={planet}
									alt=''
								/>
								<img
									className='music-planet mp2 js-scroll slide-left-long'
									src={planet}
									alt=''
								/>
								<img
									className='music-planet mp3 js-scroll slide-right-long'
									src={planet}
									alt=''
								/>
								<img
									className='music-planet mp4 js-scroll slide-left-long2'
									src={planet}
									alt=''
								/>
							</>
						)}

						<span className='section-title js-scroll fade-in-bottom'>
							My Music
						</span>
						<span className='section-desc js-scroll fade-in-bottom'>
							- my music can be found on all streaming platforms -
						</span>

						<div className='music-scplayer js-scroll fade-in-bottom'>
							<div className='music-tabs-row'>
								<button
									className={`music-tab-button ${
										edmCinematicTab && 'music-tab-enabled'
									}`}
									onClick={() => {
										setEdmCinematicTab(true);
										setSCorSpofity(true);
									}}
								>
									Electronic
								</button>
								<button
									className={`music-tab-button ${
										!edmCinematicTab && 'music-tab-enabled'
									}`}
									onClick={() => {
										setEdmCinematicTab(false);
										setSCorSpofity(false);
									}}
								>
									Cinematic
								</button>
							</div>

							<div className='music-scplayer-button-row'>
								<span>
									{'Select Music Player'}
									<RightArrow className='music-arrow' />
								</span>
								<Soundcloud
									className='music-scplayer-button'
									onClick={() => setSCorSpofity(true)}
									style={{ fill: sCorSpofity ? '#F26F23' : '#000' }}
								/>
								<Spotify
									className='music-scplayer-button'
									onClick={() => setSCorSpofity(false)}
									style={{
										fill: !sCorSpofity ? '#1ED760' : '#000',
										padding: '2px',
									}}
								/>
							</div>

							{edmCinematicTab ? (
								sCorSpofity ? (
									<SoundcloudPlayer type={'edm'} />
								) : (
									<SpotifyPlayer type={'edm'} />
								)
							) : sCorSpofity ? (
								<SoundcloudPlayer type={'cinematic'} />
							) : (
								<SpotifyPlayer type={'cinematic'} />
							)}
						</div>
					</div>

					<div className='section highlights' id='highlights'>
						<div className='highlights-container'>
							<div
								className={`highlights-text-1 ${
									width > 720
										? 'js-scroll slide-left'
										: 'js-scroll fade-in-bottom'
								}`}
							>
								<span className='section-title'>Highlights</span>
								<br></br>
								<br></br>
								<h2>
									{'My music has been played at shows by Alison Wonderland, '}
									{width > 1000 && <br></br>}
									RL Grime, WHIPPED CREAM, Zeds Dead, and more.
								</h2>
								<br></br>
								Alison Wonderland opened almost all of her shows in 2021 and
								2022 with Light of Mine.
								<br></br>
								<br></br>
								{'Illenium included Light of Mine in his '}
								<a
									className='text-link'
									href='https://www.1001tracklists.com/tracklist/9nmv361/annie-nightingale-illenium-illumi-annie-nightingale-show-2022-01-18.html'
									target='_blank'
									rel='noopener noreferrer'
								>
									Annie Nightingale mix.
								</a>
								<br></br>
								<br></br>
								{'View all support for Light of Mine on '}
								<a
									className='text-link'
									href='https://www.1001tracklists.com/track/r0v1hcp/whipped-cream-light-of-mine/index.html'
									target='_blank'
									rel='noopener noreferrer'
								>
									Tracklists1001.
								</a>
								{width > 720 && (
									<>
										<br></br>
										{'_'}
									</>
								)}
							</div>

							{<AWVideo width={width} />}
						</div>

						<div className='highlights-container'>
							<div
								className={`highlights-text-1 ${
									width > 720
										? 'js-scroll slide-left'
										: 'js-scroll fade-in-bottom'
								}`}
							>
								<span className='section-title'>Features on YouTube</span>
								<br></br>
								<br></br>
								{
									'Featured on channels such as Trap Nation (30+ million subs), Valiant, Monstercat Uncaged, Trap Cosmos, and more.'
								}
								<br></br>
								<br></br>
								{'View the entire YouTube playlist '}
								<a
									className='text-link'
									href='https://youtube.com/playlist?list=PLTVZ0ZrbY1x84-3HONyDAzst1TGFFMEoq'
									target='_blank'
									rel='noopener noreferrer'
								>
									here.
								</a>
								{width > 720 && (
									<>
										<br></br>
										{'_'}
									</>
								)}
							</div>

							{<YoutubePlaylist width={width} />}
						</div>

						<div className='highlights-container'>
							<div
								className={`highlights-text-1 ${
									width > 720
										? 'js-scroll slide-left'
										: 'js-scroll fade-in-bottom'
								}`}
							>
								<span className='section-title'>Sync</span>
								<br></br>
								<br></br>
								My music has been used in video games such as Rocket League and
								in the trailers for EA's F1 2022.
								{width > 720 && (
									<>
										<br></br>
										{'_'}
									</>
								)}
							</div>
							<div
								className={`highlights-sync-gallery ${
									width > 720
										? 'js-scroll slide-right'
										: 'js-scroll fade-in-bottom'
								}`}
							>
								<img className='highlights-sync-image' src={sync1} alt='' />
								<div className='sync-filler' />
								<img className='highlights-sync-image' src={sync2} alt='' />
								<div className='sync-filler' />
							</div>
						</div>
					</div>

					<div className='section music-video' id='musicvideo'>
						<span className='section-title js-scroll fade-in-bottom'>
							Jimorrow Music Video
						</span>
						<span className='section-desc js-scroll fade-in-bottom'>
							- make sure to watch in 4k quality -
						</span>
						<JimorrowMusicVideo width={width} />
					</div>

					<div className='section gallery' id='gallery'>
						<span className='section-title js-scroll fade-in-bottom'>
							Jimorrow Gallery
						</span>
						<span className='section-desc js-scroll fade-in-bottom'>
							- I make all the art for my music with occasional help from my
							sister -
							<br />
							Here are some old cover arts
						</span>
						<div className='gallery-container js-scroll fade-in-bottom'>
							{gallerySrcList.map((img, index) => {
								return (
									<img
										key={index}
										className='gallery-image'
										src={img}
										alt=''
										onClick={() => {
											setGalleryNumberSelected(index);
											setGalleryModalOpen(true);
										}}
									/>
								);
							})}
						</div>
					</div>

					<div className='section contact' id='contact'>
						<span className='section-title js-scroll fade-in-bottom'>
							Contact Me
						</span>
						<span className='section-desc js-scroll fade-in-bottom'>
							- reach out to me for business inquiries or just to say hi -
							<br></br>- I also offer mixing and mastering here
							<a className='home-link-mixing' href='/#/mixing-mastering'>
								here
							</a>
							-
						</span>
						<div className='contact-email js-scroll slide-right'>
							jimorrow.com@gmail.com
						</div>
					</div>

					<div className='footer'>
						{/* <div className="footer-quote">
              <div className="js-scroll slide-left">
                "Life is a journey. You make it exciting."
                <br></br>
                <span>-JIMORROW</span>
              </div>
            </div> */}
						<div className='footer-text'>@2022 WEBSITE BUILT BY JIMORROW</div>
						<img className='ymir-icon' src={jimorrowYmirIcon} alt='' />
						<div className='hey'>hey</div>
						<img className='footer-image' src={footerImage} alt='' />
					</div>
				</>
			)}

			<GalleryModal
				open={galleryModalOpen}
				onClose={() => {
					setGalleryModalOpen(false);
					setGalleryNumberSelected(null);
				}}
				galleryNumberSelected={galleryNumberSelected}
				setGalleryNumberSelected={(e) => setGalleryNumberSelected(e)}
			/>
		</div>
	);
}

export function GalleryModal({
	open,
	onClose,
	galleryNumberSelected,
	setGalleryNumberSelected,
}) {
	if (!open) return null;
	return (
		<>
			<div className='gallery-modal-backdrop' onClick={onClose} />
			<div className='gallery-modal-content'>
				<img
					className='gallery-modal-image'
					src={gallerySrcList[galleryNumberSelected]}
					alt=''
				/>
				<div className='gallery-modal-info'>
					<Exit className='gallery-modal-exit' onClick={onClose} />

					<div className='gallery-modal-title'>
						{galleryInfoList[galleryNumberSelected].title}
					</div>
					<div className='gallery-modal-desc'>
						{galleryInfoList[galleryNumberSelected].desc}
					</div>

					<div className='gallery-modal-arrows'>
						<ArrowLeft
							className='gallery-modal-arrow'
							onClick={() =>
								setGalleryNumberSelected(
									galleryNumberSelected === 0
										? gallerySrcList.length - 1
										: galleryNumberSelected - 1
								)
							}
						/>

						<ArrowRight
							className='gallery-modal-arrow'
							onClick={() =>
								setGalleryNumberSelected(
									galleryNumberSelected === gallerySrcList.length - 1
										? 0
										: galleryNumberSelected + 1
								)
							}
						/>
					</div>
				</div>
			</div>
		</>
	);
}
