export const SoundcloudPlayer = ({ type }) => {
	return (
		<>
			<iframe
				title='1'
				width='100%'
				height='500'
				scrolling='no'
				frameBorder='no'
				allow='autoplay'
				src={
					type === 'edm'
						? 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1447387111&color=%23000000&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=true'
						: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1831482984&color=%23000000&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=true'
				}
			></iframe>
		</>
	);
};

export const SpotifyPlayer = ({ type }) => {
	return (
		<>
			<iframe
				title='2'
				style={{ borderRadius: '12px' }}
				src={
					type === 'edm'
						? 'https://open.spotify.com/embed/playlist/4JjrVnfCxfky0zQvCi989w?utm_source=generator'
						: 'https://open.spotify.com/embed/playlist/3vZpfUDUNiq3xPnG8lDSzu?utm_source=generator'
				}
				width='100%'
				height='500'
				frameBorder='0'
				allowfullscreen=''
				allow='autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture'
			></iframe>
		</>
	);
};

export const AWVideo = ({ width }) => {
	return (
		<>
			<iframe
				className={`highlights-yt-embed ${
					width > 720 ? 'js-scroll slide-right' : 'js-scroll fade-in-bottom'
				}`}
				width='100%'
				height='auto'
				src='https://www.youtube.com/embed/WLUJr2Ct3kE'
				title='YouTube video player'
				frameBorder='0'
				allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
				allowFullScreen
			></iframe>
		</>
	);
};

export const YoutubePlaylist = ({ width }) => {
	return (
		<>
			<iframe
				className={`highlights-yt-embed ${
					width > 720 ? 'js-scroll slide-right' : 'js-scroll fade-in-bottom'
				}`}
				width='100%'
				height='auto'
				src='https://www.youtube.com/embed/videoseries?list=PLTVZ0ZrbY1x84-3HONyDAzst1TGFFMEoq'
				title='YouTube video player'
				frameBorder='0'
				allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
				allowFullScreen
			></iframe>
		</>
	);
};

export const JimorrowMusicVideo = ({ width }) => {
	return (
		<>
			<iframe
				className='music-video-yt-embed js-scroll fade-in-bottom'
				width='100%'
				height='auto'
				src='https://www.youtube.com/embed/_AyWfn8Pe-0'
				title='YouTube video player'
				frameBorder='0'
				allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
				allowFullScreen
			></iframe>
		</>
	);
};
