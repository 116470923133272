import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./App.css";
import "./animate.css";
import App from "./App";
import {
  Routes,
  Route,
  Navigate,
  HashRouter as Router,
} from "react-router-dom";
import MixingMastering from "./MixingMastering";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <>
      <Router>
        <Routes>
          <Route exact strict path="/" element={<App />} />
          <Route
            exact
            strict
            path="/mixing-mastering"
            element={<MixingMastering />}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </>
  </React.StrictMode>
);
